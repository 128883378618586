/* utilities  */
.swiper {
  width: 100%;
  height: 100%;
  padding: 60px 0;
}

.swiper-slide {
  margin: 0;
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 6px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet {
  width: 20px;
  border-radius: 5px;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(1);
}

.brand-slider .swiper-slide {
  margin: 0;
}

.brands-slider .swiper-button-prev {
  background: transparent;
  width: 50px;
  height: 50px;
  border: 2px solid #287ff9;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
  opacity: 0.8;
}

.brands-slider .swiper-button-prev::after {
  font-size: 14px;
  font-weight: 700;
  color: #287ff9;
  transition: all ease 0.3s;
}

.brands-slider .swiper-button-prev:hover {
  border-color: #287ff9;
  background-color: #287ff9;
  opacity: 1;
}
.brands-slider .swiper-button-prev:hover::after {
  color: white;
}

.brands-slider .swiper-button-next {
  background: transparent;
  width: 50px;
  height: 50px;
  border: 2px solid #287ff9;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
  opacity: 0.8;
}

.brands-slider .swiper-button-next::after {
  font-size: 14px;
  font-weight: 700;
  color: #287ff9;
  transition: all ease 0.3s;
}
.brands-slider .swiper-button-next:hover {
  border-color: #287ff9;
  background-color: #287ff9;
  opacity: 1;
}
.brands-slider .swiper-button-next:hover::after {
  color: white;
}

/* services slider  */

/* testimonials-slider */
.testimonials-slider .swiper-pagination-bullet {
  background-color: white;
  opacity: 0.8;
}
.testimonials-slider .swiper-pagination-bullet-active {
  background-color: #287ff9;
}

/* showcases slider  */
.showcases-slider .swiper-button-prev {
  background: transparent;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
  opacity: 0.8;
}

.showcases-slider .swiper-button-prev::after {
  font-size: 14px;
  font-weight: 700;
  color: white;
  transition: all ease 0.3s;
}

.showcases-slider .swiper-button-prev:hover {
  border-color: #287ff9;
  background-color: #287ff9;
  opacity: 1;
}
.showcases-slider .swiper-button-prev:hover::after {
  color: white;
}

.showcases-slider .swiper-button-next {
  background: transparent;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
  opacity: 0.8;
}

.showcases-slider .swiper-button-next::after {
  font-size: 14px;
  font-weight: 700;
  color: white;
  transition: all ease 0.3s;
}
.showcases-slider .swiper-button-next:hover {
  border-color: #287ff9;
  background-color: #287ff9;
  opacity: 1;
}
.showcases-slider .swiper-button-next:hover::after {
  color: white;
}

/* service slider  */

.service-category-slider > .swiper-wrapper {
  padding: auto 50px;
}

.service-category-slider .swiper-button-prev {
  background: yellow;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
}
.service-category-slider .swiper-button-prev::after {
  font-size: 14px;
  font-weight: 700;
  color: black;
}
.service-category-slider .swiper-button-next {
  background: yellow;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
}
.service-category-slider .swiper-button-next::after {
  font-size: 14px;
  font-weight: 700;
  color: black;
}

.services-slider .swiper-slide {
  margin: 0;
}

.services-slider .swiper-button-prev {
  background: transparent;
  width: 50px;
  height: 50px;
  border: 2px solid #287ff9;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
  opacity: 0.8;
}

.services-slider .swiper-button-prev::after {
  font-size: 14px;
  font-weight: 700;
  color: #287ff9;
  transition: all ease 0.3s;
}

.services-slider .swiper-button-prev:hover {
  border-color: #287ff9;
  background-color: #287ff9;
  opacity: 1;
}
.services-slider .swiper-button-prev:hover::after {
  color: white;
}

.services-slider .swiper-button-next {
  background: transparent;
  width: 50px;
  height: 50px;
  border: 2px solid #287ff9;
  border-radius: 100%;
  opacity: 1;
  transition: all ease 0.3s;
  opacity: 0.8;
}

.services-slider .swiper-button-next::after {
  font-size: 14px;
  font-weight: 700;
  color: #287ff9;
  transition: all ease 0.3s;
}
.services-slider .swiper-button-next:hover {
  border-color: #287ff9;
  background-color: #287ff9;
  opacity: 1;
}
.services-slider .swiper-button-next:hover::after {
  color: white;
}

@media only screen and (max-width: 570px) {
  .swiper-slide {
    margin: 0;
  }
}
