/* utilities */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
p {
  font-family: "Roboto", sans-serif;
}
.overlay-none::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0.5;
  z-index: -1;
}
.overlay-primary::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
}
.overlay-secondary::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #14287b;
  opacity: 0.8;
  z-index: -1;
}
.rtin-title {
  font-size: 36px;
  color: #14287b;
  line-height: 1.3;
  margin-bottom: 18px;
}
.custom-button {
  background-color: #fef22e;
  color: rgb(75, 72, 72);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  padding: 14px 25px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  z-index: 2;
  transition: all 0.5s ease-out;
}

.custom-button::before {
  content: "";
  height: 100%;
  width: 0;
  background-color: #14287b;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: all 0.45s ease-out;
}

.custom-button:hover::before {
  width: 100%;
}
.custom-button:hover {
  color: white;
}
.custom-shadow {
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.27);
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: "";
  width: 48px;
  height: 48px;
  border: 2px solid #14287b;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 2s ease-in-out infinite;
}
.loader::after {
  border-color: #ff3d00;
  animation-delay: 1s;
}

.react-datepicker-wrapper {
  width: 100%;
}

.loader-card {
  display: inline-block;
  position: relative;
  width: 215px;
  height: 220px;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
    linear-gradient(#ddd, #ddd);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 215px 220px, 215px 130px, 100px 15px, 150px 15px;
  background-position: -215px 0, 0 0, 15px 150px, 15px 180px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 215px 0, 0 0, 15px 150px, 15px 180px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
